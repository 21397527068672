import { jsonp, ajax } from '@ifeng-fe/ui_base';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */

if (typeof window !== 'undefined' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

const md5 = require('md5');

const createJsonpCallbackName = (str, num) => {
    num = num ? num : 0;
    let jsonpCallbackName = `_${md5(`${str}_${num}`)}`;

    /* eslint-disable */
    while (typeof window === 'object' && window[jsonpCallbackName]) {
        /* eslint-enable */
        num++;
        jsonpCallbackName = `_${md5(`${str}_${num}`)}`;
    }

    return jsonpCallbackName;
};

export const getiVideoData = async id => {
    const jsonpCallback = createJsonpCallbackName(id, new Date().getTime());

    return await jsonp(`${apiBaseUrl}/getVideoData/${id}/${jsonpCallback}`, {
        jsonpCallback,
        cache: false,
    });
};

export const getQrcodeImage = async url => {
    const res = await jsonp('https://qrcode.ifeng.com/qrcode.php', {
        data: {
            url,
        },
        jsonCallback: createJsonpCallbackName('getQrcode'),
        cache: false,
    });

    return res?.qrcode_url || '';
};

// 获取URL查询参数
export const getQuery = (url, key) => {
    let value = '';

    try {
        const urlObj = new URL(url);
        const { searchParams } = urlObj;

        if (searchParams.has(key)) {
            value = searchParams.get(key);
        }
    } catch (e) {
        console.error(e);
    }

    return value;
};
