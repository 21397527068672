import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getQrcodeImage, getQuery } from '@src/services/api';
import styles from './index.css';

class Layout extends React.PureComponent {
    state = {
        qrcodeImage: '',
    };

    async componentDidMount() {
        const url = getQuery(location.href, 'url') || 'https://a.ifeng.com';
        const qrcodeImage = await getQrcodeImage(decodeURIComponent(url));

        this.setState({
            qrcodeImage,
        });
    }

    componentWillUnmount() {}

    render() {
        const { qrcodeImage } = this.state;

        return (
            <div className={styles.wrap}>
                <div className={styles.top}>
                    {/* <video
                        className={styles.video_player}
                        src="https://x0.ifengimg.com/fe/custom/2022_03_24/download.fe44354f7666.mp4"
                        autoPlay
                        loop
                        muted
                    /> */}
                    <img
                        className={styles.video_player}
                        src="https://x0.ifengimg.com/ucms/2024_08/E81AB6653FDACB2F09F7ACF4D4590E9CDA636300_size273_w750_h1556.jpg"
                        alt=""
                    />
                    <img src={qrcodeImage} className={styles.topQrcodeImg} />
                </div>
                <div className={styles.help}>
                    <img
                        className={styles.helpImage}
                        src="https://x0.ifengimg.com/ucms/2023_42/343988E99AF79D13A96E3597BEDB39308A98DA36_size1841_w2878_h1582.png"
                    />
                    <img src={qrcodeImage} className={styles.helpQrcodeImg} />
                </div>
            </div>
        );
    }
}

export default errorBoundary(Layout);
